<template>
	<page>
		<module enable-flex>
			<m-search ref="search" @search="searchHandle">
				<m-search-item label="入库编号">
					<c-input name="instorage_number"></c-input>
				</m-search-item>
				<m-search-item label="状态">
					<c-checkbox-group name="state" :options="stateOptions"></c-checkbox-group>
				</m-search-item>
				<m-search-item label="仓库">
					<c-select name="warehouse_id" options="WAREHOUSE_LIST" ></c-select>
				</m-search-item>
				<m-search-item label="入库来源">
					<c-radio-group name="source_type" :options="sourceTypeOptions" v-model="source_type"></c-radio-group>
				</m-search-item>
				<m-search-item label="入库类型" v-if="source_type == 1">
					<c-select :options="DIC_INSTORAGE_SOURCE" name="source_code"></c-select>
				</m-search-item>
				<m-search-item label="委外单号" v-if="source_type == 2 ">
					<c-input name="iconsigned_order_number"></c-input>
				</m-search-item>
				<m-search-item label="操作员工">
					<c-input name="yg_name"></c-input>
				</m-search-item>
				<m-search-item label="创建时间" colspan="2">
					<c-datetime start-name="start_date" end-name="end_date" range></c-datetime>
				</m-search-item>
				<m-search-item label="核准时间" colspan="2">
					<c-datetime start-name="approve_start_date" end-name="approve_end_date" range></c-datetime>
				</m-search-item>
			</m-search>
			<m-operate>
				<c-button v-if="auth('b_instorage_edit')" color="sys" @click="addStorage(3, '流程卡')">创建入库单</c-button>
			</m-operate>
			<c-table ref="table" height="grow" >
				<c-table-column
					label="入库编号"
					width="100"
					name="instorage_number"
				>
				</c-table-column>
				<c-table-column
					label="状态"
					width="70"
					name="state"
				>
					<template #default="{data}">
						<span v-if="data.state == -2" style="color:blue">草稿</span>
						<span v-if="data.state == 1" style="color:green">已核准</span>
					</template>
				</c-table-column>
				<c-table-column
					label="仓库"
					width="80"
					name="warehouse_name"
				>
				</c-table-column>
				
				<c-table-column
					label="来源"
					width="90"
					name="source_type"
				>
					<template #default="{data}">
						<span v-if="data.source_type == 1" ><state dict="DIC_INSTORAGE_SOURCE" async :value="data.source_code"></state></span>
						<span v-else-if="data.source_type == 2" style="color:blue">委外入库</span>
					</template>
				</c-table-column>
				
				<c-table-column
					label="数量"
					width="70"
					name="product_count"
				>
				</c-table-column>
				
				<c-table-column
					label="说明"
					width="200"
					name="remark"
				>
				</c-table-column>
				
				<c-table-column
					label="操作人"
					width="80"
					name="yg_name"
				>
				</c-table-column>
				
				<c-table-column
					label="创建人"
					width="80"
					name="create_user_realname"
				>
				</c-table-column>
				
				<c-table-column
					label="创建时间"
					width="140"
					name="create_date"
				>
				</c-table-column>
				
				<c-table-column
					label="核准时间"
					width="140"
					name="approve_date"
				>
				</c-table-column>
				
				<c-table-column
					type="button"
					label="操作"
					width="220"
					button-limit="4"
				>
					<template v-slot="{data}">
						<c-table-button @click="detailStorage(data)">查看</c-table-button>
						<c-table-button v-if="data.state == -2 && auth('b_instorage_edit')" @click="editStorage(data)">编辑</c-table-button>
						<c-table-button v-if="data.state == -2 && auth('b_instorage_edit')" @click="approveStorage(data)">核准</c-table-button>
						<c-table-button v-if="data.state == -2 && auth('b_instorage_edit')" @click="delStorage(data)">删除</c-table-button>
					</template>
				</c-table-column>
			</c-table>
		</module>

  </page>
</template>

<script>
import {cryptoJs} from '@deanwu/vue-component-library'
import {asyncMapState} from '@/store'
import {mapState} from 'vuex'

export default {
	name: 'm_instorage',
	components: {
		
	},
	data() {
		return {
			source_type: 0,
			stateOptions: [{
				name: '草稿',
				value: -2
			}, {
				name: '已核准',
				value: 1
			}],
			sourceTypeOptions: [
				{
					name: '普通入库',
					value: 1
				}, {
					name: '委外入库',
					value: 2
				}
			]
		}
	},
	computed: {
		...mapState(['systemConfig']),
		...asyncMapState(['DIC_INSTORAGE_SOURCE'])
	},
	mounted() {
		this.$refs.search.search();
		this.$bus.$on('instorageList.update', page => {
			this.$refs.table.update(page);
		});
	},
	methods: {
		//搜索
		searchHandle(data) {
			this.$refs.table.load({
				url: '/wms/in_storage/list',
				data
			});
		},
		//新增入库单
		addStorage(val){
			this.navigateTo('in_storage/add')
		},
		//跳转至编辑入库单
		editStorage(val){
			this.navigateTo('in_storage/edit/'+val.id)
		},
		//跳转至详情入库单
		detailStorage(val){
			this.navigateTo('in_storage/detail/'+val.id)
		},
		//核准
		approveStorage(val){
			this.$confirm({
				title: '确认',
				message: '确定要核准入库单【'+val.instorage_number+'】吗？',  // 提示内容,
				buttonName: '确定',  // 确定按钮文字
				cancelButton: true,  // 是否显示取消按钮
				resolve: () => {
					this.request({
						url: '/wms/in_storage/approve',
						data: {id : val.id},
						loading: true,
						success: data => {
							this.$message({
								message: '核准成功',
								type: 'success'
							});
							this.$refs.table.update();
						}
					});
				},  // 确定按钮回调
				cancel: () => {},  // 取消按钮回调
			});
		},
		//删除
		delStorage(val) {
			this.$confirm({
				title: '确认',
				message: '确定要删除入库单【'+val.instorage_number+'】吗？',  // 提示内容,
				buttonName: '确定',  // 确定按钮文字
				cancelButton: true,  // 是否显示取消按钮
				resolve: () => {
					this.request({
						url: '/wms/in_storage/remove',
						data: {id : val.id},
						loading: true,
						success: data => {
							this.$message({
								message: '删除成功',
								type: 'success'
							});
							this.$refs.table.update();
						}
					});
				},  // 确定按钮回调
				cancel: () => {},  // 取消按钮回调
			});
		}
  }
}
</script>